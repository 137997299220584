// Generated by Framer (37fb21e)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-a0FWV"

const variantClassNames = {iBkRz7HV0: "framer-v-hm2llg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({footerOptionValue, height, id, width, ...props}) => { return {...props, hLXbBOalJ: footerOptionValue ?? props.hLXbBOalJ ?? "ΑΡΧΙΚΗ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;footerOptionValue?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, hLXbBOalJ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "iBkRz7HV0", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-hm2llg", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iBkRz7HV0"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1pvbmEgUHJvIFRoaW4=", "--framer-font-family": "\"Zona Pro Thin\", \"Zona Pro Thin Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>ΑΡΧΙΚΗ</motion.p></React.Fragment>} className={"framer-c8z3ql"} fonts={["CUSTOM;Zona Pro Thin"]} layoutDependency={layoutDependency} layoutId={"JgrUZp5sj"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={hLXbBOalJ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-a0FWV.framer-dq8sdo, .framer-a0FWV .framer-dq8sdo { display: block; }", ".framer-a0FWV.framer-hm2llg { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 18px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-a0FWV .framer-c8z3ql { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-a0FWV.framer-hm2llg { gap: 0px; } .framer-a0FWV.framer-hm2llg > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-a0FWV.framer-hm2llg > :first-child { margin-left: 0px; } .framer-a0FWV.framer-hm2llg > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 53
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"hLXbBOalJ":"footerOptionValue"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroibVJi0vl: React.ComponentType<Props> = withCSS(Component, css, "framer-a0FWV") as typeof Component;
export default FrameroibVJi0vl;

FrameroibVJi0vl.displayName = "FooterOption";

FrameroibVJi0vl.defaultProps = {height: 18, width: 53};

addPropertyControls(FrameroibVJi0vl, {hLXbBOalJ: {defaultValue: "ΑΡΧΙΚΗ", displayTextArea: false, title: "FooterOptionValue", type: ControlType.String}} as any)

addFonts(FrameroibVJi0vl, [{explicitInter: true, fonts: [{family: "Zona Pro Thin", source: "custom", url: "https://framerusercontent.com/assets/0PFZUaXG4luLGpfHJIX87OTlMrA.woff2"}]}], {supportsExplicitInterCodegen: true})